import axios from 'axios';

import customAxios from './Axios';
import { getConfig } from '../../AppConfig';
import {
  setAccessToken,
  setRefreshToken,
} from '../../redux/legacy/reducers/ducks/legacy/AuthorizationDuck';

const config = getConfig();

let refreshPromise = null;

const logoutUser = (store) => {
  // dispatch an action to trigger the logout saga keeping the two parts decoupled
  store.dispatch(setAccessToken(null));
  store.dispatch(setRefreshToken(null));
  refreshPromise = null;
};

export const requestInterceptor = (request, store) => {
  if (!request.headers) {
    request.headers = {};
  }
  if (
    !request.headers.Authorization &&
    store.getState()?.authorization?.accessToken?.token
  ) {
    request.headers.Authorization = `Bearer ${
      store.getState()?.authorization?.accessToken?.token
    }`;
  }

  const { useFakeData } = config;

  if (useFakeData === 'true') {
    request.headers['X-API-FakeData'] = 'random';
  }

  request.headers['Content-Type'] = 'application/json';
  request.headers['X-App-Version'] = config.version;
  request.headers['X-App-Source'] =
    config.appType === 'restaurant'
      ? 'restaurant-portal-web'
      : 'consumer-portal-web';
  request.headers['X-App-OS'] =
    window?.navigator?.platform ||
    window?.navigator?.userAgentData?.platform ||
    'unknown';

  return request;
};

const isRefreshTokenErrorCheck = (err) =>
  err?.response?.status === 404 &&
  err?.response?.data?.message === 'token not found';

export const refreshToken = async (store) => {
  let result = false;

  try {
    const token = store.getState()?.authorization?.refreshToken?.token;

    if (!token) {
      throw new Error('No refresh token');
    }

    const { data } = await axios.post(
      `${config.apiBaseUrl}account/refresh`,
      {
        token,
      },
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );

    store.dispatch(setAccessToken(data));
    refreshPromise = null;

    result = true;
  } catch (err) {
    if (isRefreshTokenErrorCheck(err)) {
      logoutUser(store);
    }
    result = false;
  }

  return result;
};

// Add response handling here
export const responseSuccessInterceptor = ({ data }) => data;

export const responseFailInterceptor = async (err, store) => {
  if (isRefreshTokenErrorCheck(err)) {
    if (!refreshPromise) {
      refreshPromise = refreshToken(store);
    }
    const isReseted = await refreshPromise;
    if (isReseted) {
      const updatedError = { ...err };
      updatedError.config.headers.Authorization = `Bearer ${
        store.getState()?.authorization?.accessToken?.token
      }`;
      return customAxios(updatedError.config);
    }
  }
  // Add error handling here
  return Promise.reject(err?.response);
};

export const setupInterceptors = (store) => {
  customAxios.interceptors.request.use((request) =>
    requestInterceptor(request, store)
  );
  customAxios.interceptors.response.use(
    responseSuccessInterceptor,
    async (err) => responseFailInterceptor(err, store)
  );

  return customAxios;
};
